a.logo {
  padding: 10px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06) !important;
  margin-top: 10px !important;
  background-color: white !important; }
  a.logo img {
    margin-top: -10px;
    width: 280px; }
    @media all and (max-width: 1000px) {
      a.logo img {
        margin-top: -34px;
        width: 150px; } }
  a.logo #group_assoc {
    text-align: right;
    color: #423741 !important;
    padding-left: 21px;
    font-size: 15px; }
    a.logo #group_assoc a {
      color: #6EA19A !important; }

#4tools div.row > div {
  height: 1000px !important; }

.imgfix_src_img {
  width: 100%; }

.about-text {
  right: 30% !important;
  box-shadow: 0px 0px 40px #444 !important; }

.icon i {
  margin-top: -6px; }

div.header-text a {
  color: #6EA19A !important; }

div.about #imgfix_wrapper_layer_0_0 {
  height: 700px; }

#welcome .container {
  margin-top: 200px; }

.btn-primary {
  background-color: #6EA19A;
  border-color: #6EA19A; }

.header-hexagon-bg {
  right: 0; }

.welcome-area {
  background-color: #F7FAFD; }

.hexagon-in2 {
  max-width: 100%; }

.paymentmethods svg {
  display: block;
  text-align: center;
  margin: 0 auto; }

.paymentmethods i, .paymentmethods span, .paymentmethods .fa {
  color: white; }

.paymentmethods i, .paymentmethods .fa {
  display: block;
  font-size: 50px; }

svg.fa {
  display: block;
  margin: 0 auto; }
  @media all and (max-width: 1000px) {
    svg.fa {
      float: none !important;
      margin-top: 30px; } }

a.btn-nav-line, .btn-primary-line {
  height: auto !important; }

#trafficlights img {
  max-height: 400px !important;
  margin-top: -50px !important;
  margin-left: 90px !important; }
